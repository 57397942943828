import React, { Fragment, useCallback, useEffect, useState } from "react";
import {
  Button,
  ButtonColors,
  ButtonStyles,
  ButtonTypes,
  Input,
} from "icr-shared-components/dist";
import { useHistory } from "react-router";
import { RootState } from "../../reducers";
import { bindActionCreators, Dispatch } from "redux";
import { Action, fetchCountriesList, setLoading } from "../../actions";
import { connect } from "react-redux";
import { updateProfileAddress } from "../../actions/profile";
import { formConfig } from "./formConfig";
import { useValidateFrom } from "../../utils/useValidateFrom";
import { prepareCountriesEntitiesDropdownOptions } from "../../utils/react";
import { useTranslation } from "react-i18next";
import { i18n } from "../../locale";
import { Languages } from "../../constants/enums";
import { toaster } from "../../utils/toaster";
import { DefaultRouteNames, getRouteByLanguage } from "../../routes";

interface Props {
  profileAddress: any;
  updateProfileAddress: any;
  fetchCountriesList: any;
}

type LandOption = {
  [key: string]: string;
};

const Address = ({
  profileAddress,
  updateProfileAddress,
  fetchCountriesList,
}: Props) => {
  const locale = i18n.language.split("-")[0] ?? "de";
  const { t: translate } = useTranslation(["profile"]);
  const t = (key: string, defaultValue: string) =>
    translate(key, { ns: "profile", defaultValue });

  const [isDisabledButton, setIsDisabledButton] = useState(true);
  const [landOptions, setLandOptions] = useState<LandOption[]>([]);

  const onLoadPage = useCallback(async () => {
    setLoading(true);
    const countries = await fetchCountriesList(Languages[locale]);
    setLoading(false);
    setLandOptions(prepareCountriesEntitiesDropdownOptions(countries));
  }, [fetchCountriesList, locale]);

  useEffect(() => {
    onLoadPage();
  }, [onLoadPage]);

  const [address, setAddress] = useState({
    addressRow1: "",
    addressRow2: "",
    zipCode: 0,
    city: "",
    countryCode: "",
    country: "",
  });

  const [isBlurred, setIsBlurred] = useState({
    addressRow1: false,
    addressRow2: false,
    zipCode: false,
    city: false,
    countryCode: false,
  });

  const history = useHistory();
  const validation = useValidateFrom("profile", formConfig, { ...address });

  useEffect(() => {
    const { addressRow1, addressRow2, zipCode, city, countryCode } =
      profileAddress;
    const country =
      landOptions.find(({ value }) => value === countryCode)?.label || "";
    setAddress({
      addressRow1,
      addressRow2,
      zipCode,
      city,
      country,
      countryCode,
    });
  }, [landOptions, profileAddress]);

  useEffect(() => {
    setIsDisabledButton(
      !validation ||
        !Object.values(validation).every(
          (field) => field["validation"].type !== "error"
        )
    );
  }, [validation]);

  const handleSubmit = async () => {
    setIsDisabledButton(true)
    try {
    const payload = { ...address };
    const response = await updateProfileAddress(payload);
    history.push(getRouteByLanguage(DefaultRouteNames.PROFILE));
    response.status === 201 &&
      toaster({
        message: t(
          "address.updateToast",
          "Ihre neue Adresse wird übermittelt. Es kann ein paar Tage dauern bis die Änderung wirksam wird."
        ),
        type: "success",
      });
    } catch (error) {
      console.error(error);
    }
    finally{
      setIsDisabledButton(false)
    }
  };

  const isRequiredField = " *";

  return (
    <Fragment>
      <div className="row mb-32 mb-0-tablet">
        <div className="col-md-6 col-sm-12">
          <Input
            type="text"
            name="addressRow1"
            value={address.addressRow1 || ""}
            onChange={(_name, value) =>
              setAddress({ ...address, addressRow1: value })
            }
            placeholder="Adresszeile 1"
            onBlur={() =>
              setIsBlurred((pre) => ({ ...pre, addressRow1: true }))
            }
            validationMessageConfig={
              (isBlurred.addressRow1 ||
                validation?.addressRow1?.validation?.type === "success") &&
              validation.addressRow1.validation
            }
          >
            {t("address.adresszeile1", "Adresszeile 1") + isRequiredField}
          </Input>
        </div>
        <div className="col-md-6 col-sm-12">
          <Input
            type="text"
            name="addressRow2"
            value={address.addressRow2 || ""}
            onChange={(_name, value) =>
              setAddress({ ...address, addressRow2: value })
            }
            placeholder="Adresszeile 2"
            onBlur={() =>
              setIsBlurred((pre) => ({ ...pre, addressRow2: true }))
            }
            validationMessageConfig={
              (isBlurred.addressRow2 ||
                validation?.addressRow2?.validation?.type === "success") &&
              validation.addressRow2.validation
            }
          >
            {t("address.adresszeile2", "Adresszeile 2")}
          </Input>
        </div>
      </div>
      <div className="row mb-32">
        <div className="col-md-6 col-sm-12">
          <Input
            type="text"
            name="zip"
            value={address.zipCode || ""}
            onChange={(_name, value) =>
              setAddress({ ...address, zipCode: value })
            }
            placeholder="PLZ"
            onBlur={() => setIsBlurred((pre) => ({ ...pre, zipCode: true }))}
            validationMessageConfig={
              (isBlurred.zipCode ||
                validation?.zipCode?.validation?.type === "success") &&
              validation.zipCode.validation
            }
          >
            {t("address.plz", "PLZ") + isRequiredField}
          </Input>
        </div>
        <div className="col-md-6 col-sm-12">
          <Input
            type="text"
            name="city"
            value={address.city || ""}
            onChange={(_name, value) => setAddress({ ...address, city: value })}
            placeholder="Ort"
            onBlur={() => setIsBlurred((pre) => ({ ...pre, city: true }))}
            validationMessageConfig={
              (isBlurred.city ||
                validation?.city?.validation?.type === "success") && {
                config: validation.city.validation,
                visible: true,
              }
            }
          >
            {t("address.ort", "Ort") + isRequiredField}
          </Input>
        </div>
      </div>
      <div className="row mb-48">
        <div className="col-md-6 col-sm-12">
          <Input
            type="text"
            id="countryCode"
            name="countryCode"
            inputWrapperClass="dropdown-input"
            placeholder=""
            label="label"
            value={address.country || ""}
            selectOptions={landOptions}
            onSelected={(_name: string, valueInput: string) => {
              const land = landOptions.find(
                ({ value }) => value === valueInput
              );
              setAddress({
                ...address,
                countryCode: valueInput,
                country: land.label,
              });
            }}
            onBlur={() => setIsBlurred({ ...isBlurred, countryCode: true })}
            validationMessageConfig={
              (isBlurred.countryCode ||
                validation?.countryCode?.validation?.type === "success") &&
              validation.countryCode.validation
            }
            sort
          >
            {t("address.land", "Land") + isRequiredField}
          </Input>
        </div>
      </div>

      <div className="modal-buttons">
        <Button
          type={ButtonTypes.Text}
          color={ButtonColors.Action}
          onClick={() =>
            history.push(getRouteByLanguage(DefaultRouteNames.PROFILE))
          }
        >
          abbrechen
        </Button>

        <Button
          type={ButtonTypes.Standard}
          style={ButtonStyles.Primary}
          color={ButtonColors.Action}
          isDisabled={isDisabledButton}
          onClick={handleSubmit}
        >
          speichern
        </Button>
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state: RootState) => ({
  profileAddress: state.profile.profileAddress,
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  updateProfileAddress: bindActionCreators(updateProfileAddress, dispatch),
  fetchCountriesList: bindActionCreators(fetchCountriesList, dispatch),
  setLoading: (value: boolean) => dispatch(setLoading(value)),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(Address);
