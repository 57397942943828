import React, { FunctionComponent, useEffect, useState } from "react";
import { RootState } from "../../../reducers";
import { bindActionCreators, Dispatch } from "redux";
import { Action, setLoading } from "../../../actions";
import { connect } from "react-redux";
import {
  Button,
  ButtonColors,
  ButtonTypes,
  Input,
} from "icr-shared-components";
import { useHistory } from "react-router";
import { register, setAhvNumber } from "../../../actions/register";
import Modal from "react-modal";
import Tab from "@material/react-tab";
import { ReactComponent as CloseIcon } from "../../../assets/icons/close.svg";
import Versicherungskarte from "../../../assets/images/beispiel-versicherungskarte.png";
import Versicherungsausweis from "../../../assets/images/beispiel-versicherungsausweis.png";
import { ButtonStyles } from "icr-shared-components/dist";
import { formConfig } from "./formConfig";
import { useValidateFrom } from "../../../utils/useValidateFrom";
import { parseToApiDate } from "../../../utils/react";
import { useTranslation } from "react-i18next";
import { getRouteByLanguage, DefaultRouteNames } from "../../../routes";

interface Props {
  ahvNumber: string;
  name: string;
  lastname: string;
  birthday: string;
  setAhvNumber: (value: string) => void;
  register: any;
  sessionId: string;
  setLoading: any;
  configurations: any;
}

const Ahv: FunctionComponent<Props> = ({
  name,
  lastname,
  birthday,
  ahvNumber,
  setAhvNumber,
  register,
  sessionId,
  setLoading,
  configurations,
}) => {
  const { i18n } = useTranslation();
  const { t: translate } = useTranslation(["register", "app"]);
  const t = (key: string, defaultValue: string) =>
    translate(key, { ns: "register", defaultValue });
  const tabs = {
    CARD: "CARD",
    CERTIFICATE: "CERTIFICATE",
  };

  const history = useHistory();
  const validation = useValidateFrom("register", formConfig, { ahvNumber });

  const [modal, setModal] = useState(false);
  const [isMobileSize, setIsMobileSize] = useState(window.innerWidth <= 768);
  const [tab, setTab] = useState(tabs.CARD);
  const [isBlurred, setIsBlurred] = useState(false);

  const insuranceCertificateImage =
    configurations[i18n?.language]?.insuranceCertificateImage;

  useEffect(() => {
    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  const next = async () => {
    setLoading(true);
    try {
      const data = {
        // "registrationId": "1234-abcd-1234-abcd",
        firstname: name,
        name: lastname,
        birthDate: parseToApiDate(birthday),
        socialSecurityNumber: ahvNumber,
      };
      const response = await register(data, sessionId);
      setLoading(false);
      history.push(getRouteByLanguage(DefaultRouteNames.REGISTERPHONE));
    } catch (error) {
      console.error("error in registering", error);
    } finally {
      setLoading(false);
    }
  };

  const back = () => {
    history.push(getRouteByLanguage(DefaultRouteNames.REGISTERBIRTHDAY));
  };

  const handleWindowResize = () => {
    const width = window.innerWidth;
    const mobileSize = width < 768;
    setIsMobileSize(mobileSize);
  };

  return (
    <div className="register-birthday-page">
      <div>
        <div className="row">
          <div className="col-sm-12 col-md-6">
            <Input
              id="registerAhvInput"
              type="text"
              name="registerAhvInput"
              value={ahvNumber}
              onChange={(name, value) => setAhvNumber(value)}
              onBlur={() => setIsBlurred(true)}
              placeholder={t(
                "personalInfo.ahv.placeholder",
                "AHV-Nummer eingeben"
              )}
              inputWrapperClass="mb-16"
              validationMessageConfig={
                (isBlurred ||
                  validation?.ahvNumber?.validation?.type === "success") && {
                  config: validation.ahvNumber.validation,
                  visible: true,
                }
              }
            >
              {t("personalInfo.ahv.label", "AHV-Nummer")}
            </Input>
          </div>
        </div>

        <div className="left-align-button-wrapper">
          <Button
            onClick={() => setModal(true)}
            type={ButtonTypes.Text}
            color={ButtonColors.Action}
            className="mb-48"
          >
            {t("personalInfo.whereToFindAHV", "WO FINDE ICH MEINE AHV-NUMMER?")}
          </Button>
        </div>
      </div>

      <div className="modal-buttons">
        <Button
          id="registerBackButton"
          onClick={() => back()}
          type={ButtonTypes.Text}
          color={ButtonColors.Action}
        >
          {translate("buttons.goBack", { ns: "app", defaultValue: "Zurück" })}
        </Button>

        <Button
          id="registerNextButton"
          isDisabled={validation?.ahvNumber?.validation?.type !== "success"}
          type={ButtonTypes.Standard}
          style={ButtonStyles.Primary}
          color={ButtonColors.Action}
          onClick={() => next()}
        >
          {translate("buttons.next", { ns: "app", defaultValue: "Weiter" })}
        </Button>
      </div>

      <Modal
        isOpen={modal}
        className="modal register-modal"
        overlayClassName="modal-overlay register-modal-overlay"
        contentLabel="Register Popup"
      >
        <div className="modal-close-button" onClick={() => setModal(false)}>
          <CloseIcon />
        </div>

        <div className="register-modal-header">
          <h1 className="mb-24">
            {t("register.modal.ahv.header", "Wo finde ich meine AHV-Nummer?")}
          </h1>

          <p className="mb-24">
            {t(
              "register.modal.ahv.content",
              "Sie finden die AHV-Nummer z. B. auf Ihrer Krankenversicherungskarte \n oder auf Ihrem Pensionskassen-Versicherungsausweis."
            )}
          </p>

          <div className="tabs">
            <Tab active={tab === tabs.CARD} onClick={() => setTab(tabs.CARD)}>
              <span className="mdc-tab__text-label">
                {isMobileSize
                  ? t("register.modal.ahv.mobile.cardTab", "Karte")
                  : t(
                      "register.modal.ahv.cardTab",
                      "Krankenversicherungskarte"
                    )}
              </span>
            </Tab>

            <Tab
              active={tab === tabs.CERTIFICATE}
              onClick={() => setTab(tabs.CERTIFICATE)}
            >
              <span className="mdc-tab__text-label">
                {isMobileSize
                  ? t("register.modal.ahv.mobile.certificate", "Ausweis")
                  : t("register.modal.ahv.certificate", "Versicherungsausweis")}
              </span>
            </Tab>
          </div>
        </div>

        <div className="register-modal-content">
          <div className="register-modal-image-container d-flex flex-column ">
            {tab === tabs.CARD && (
              <img
                className="register-modal-image image-card"
                src={Versicherungskarte}
                alt="Versicherungskarte"
              />
            )}

            {tab === tabs.CERTIFICATE && (
              <img
                className="register-modal-image image-certificate "
                src={insuranceCertificateImage ?? Versicherungsausweis}
                alt="Versicherungsausweis"
              />
            )}

            {(tab === tabs.CARD || tab === tabs.CERTIFICATE) && (
              <span className={"register-modal-subtext"}>
                {t("register.modal.ahv.example", "Beispiel")}
              </span>
            )}
          </div>
        </div>
      </Modal>
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  ahvNumber: state.app.ahvNumber,
  email: state.app.email,
  name: state.app.name,
  lastname: state.app.lastname,
  birthday: state.app.birthday,
  sessionId: state.app.sessionId,
  configurations: state.app.configurations,
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  setAhvNumber: (value) => dispatch(setAhvNumber(value)),
  register: bindActionCreators(register, dispatch),
  setLoading: (value) => dispatch(setLoading(value)),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(Ahv);
