import { useEffect, useState } from "react";
import {
  Button,
  ButtonColors,
  ButtonTypes,
  ExpandableRow,
} from "icr-shared-components/dist";
import { connect, useDispatch } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { toastr } from "react-redux-toastr";

import "../Versicherungen/Versicherungen.scss";
import "./Dokumente.scss";
import Card, { Type } from "../../components/Card";
import { ReactComponent as DocumentIcon } from "../../assets/icons/Desktop/document-icon.svg";
import { ReactComponent as DownloadIcon } from "../../assets/icons/document.svg";
import { ReactComponent as Arrow } from "../../assets/icons/arrow-right.svg";
import { Top } from "../../components/Card/Content/Top";
import Pill, { PillTypes } from "../../components/Pill";
import Document from "../../components/Modals/Document";
import { RootState } from "../../reducers";
import { Action, setLoading, setDrawerLoading } from "../../actions";
import {
  DocumentList,
  DocumentSummary,
  getDocumentList,
  getDocumentsSummary,
  getPdf,
  insuranceFormSet,
  generateInsuranceFormSet,
  DocumentData,
} from "../../actions/document";
import { parseDate } from "../../utils";
import { useLocation, withRouter } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { baseURL } from "../../utils/api";
import { getRouteByLanguage, DefaultRouteNames } from "../../routes";
import {
  SET_OPEN_DOCUMENT,
  cmsSchemas,
  hashtagLinksCMSKeys,
} from "../../constants";
import { HygraphSidebarComponents } from "../../components/sidebar/HygraphSidebarComponents";
import { useGetSidebarCMSContent } from "../../hooks/cms";

interface Props {
  history: any;
  getDocumentsSummary: any;
  getDocumentList: any;
  getPdf: any;
  documentList: DocumentList;
  documentsSummary: DocumentSummary;
  documentData: DocumentData;
  openDocument: boolean;
  setLoading: (value: boolean) => Action;
  isDrawerLoading: boolean;
  setDrawerLoading: (value: boolean) => Action;
  insuranceFormSet: any;
  generateInsuranceFormSet: any;
  loading: boolean;
}

const Dokumente: React.FC<Props> = (props) => {
  const [pdfLink, setPdfLink] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [data, setData] = useState(props.documentsSummary);
  const [list, setList] = useState(props.documentList);
  const location = useLocation();
  const dispatch = useDispatch();

  const schema = cmsSchemas.documents.plural;
  const { content: cmsSidebarContent } = useGetSidebarCMSContent({ schema });
  const sidebarBoxes = cmsSidebarContent?.sidebar;

  const { t: translate } = useTranslation(["documents", "app", "common"]);
  const t = (key: string, defaultValue: string) =>
    translate(key, { ns: "documents", defaultValue });

  const getInitialData = async () => {
    try {
      props.setLoading(true);
      await props.getDocumentList();
      await props.getDocumentsSummary();
      props.setLoading(false);
    } catch (error) {
      console.error("error in getting documents page initial data", error);
    }
  };

  const onDocumentOpen = (link: string) => {
    props.setLoading(true);
    props.setDrawerLoading(false);
    setPdfLink(link);
    setIsModalOpen(true);
  };

  const onDocumentLoad = (e: any) => {
    props.setLoading(false);
    props.getDocumentList();
    props.getDocumentsSummary();
  };

  const isDocumentReadyForDisplay = () => {
    return (
      props.documentData.isReady &&
      props.documentData.fileCode &&
      !props.documentData.hasError &&
      location.pathname.includes(
        getRouteByLanguage(DefaultRouteNames.DOCUMENTS)
      )
    );
  };

  const handleGenerateFormSet = async () => {
    try {
      props.setLoading(true);

      if (isDocumentReadyForDisplay()) {
        let link =
          baseURL +
          `/documents/download?fileCode=${props.documentData.fileCode}`;
        onDocumentOpen(link);
      } else {
        let isReady = false;
        let hasError = false;
        let fileCode = null;
        let isFirstRequest = true;

        while (!isReady && !hasError) {
          const response = await props.generateInsuranceFormSet({ fileCode });

          isReady = response.isReady;
          hasError = response.hasError;
          fileCode = response.fileCode;

          if (isFirstRequest && !isReady) {
            props.setDrawerLoading(true);
            props.setLoading(false);
            isFirstRequest = false;
          } else {
            dispatch({
              type: SET_OPEN_DOCUMENT,
              payload: true,
            });
          }
          await new Promise((resolve) => setTimeout(resolve, 5000));
        }

        if (hasError) {
          props.setLoading(false);
          props.setDrawerLoading(false);
          toastr.error(
            t(
              "errorDocumentGeneration",
              "Das Dokument konnte nicht generiert werden Probierer Sies es später nochmal oder wenden Sie sich an den Support"
            )
          );
          return;
        }
      }
    } catch (error) {
      console.error(error);
      props.setDrawerLoading(false);
      toastr.error(
        t(
          "errorDocumentGeneration",
          "Das Dokument konnte nicht generiert werden Probierer Sies es später nochmal oder wenden Sie sich an den Support"
        )
      );
    }
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    getInitialData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isDocumentReadyForDisplay() && props.openDocument) {
      let link =
        baseURL + `/documents/download?fileCode=${props.documentData.fileCode}`;
      onDocumentOpen(link);
    }
  }, [
    props.documentData.isReady,
    location,
    props.documentData.fileCode,
    props.openDocument,
  ]);

  useEffect(() => {
    setData(props.documentsSummary);
    setList(props.documentList);
  }, [props.documentsSummary, props.documentList]);

  const handleSidebarCallback = (hashTagLink: string) => {
    if (hashTagLink === hashtagLinksCMSKeys.documentOrdering) {
      handleGenerateFormSet();
    }
  };

  return (
    <div className="dokumente-page">
      <div className="page-top">
        <Button
          type={ButtonTypes.Text}
          color={ButtonColors.Action}
          onClick={() =>
            props.history.push(getRouteByLanguage(DefaultRouteNames.DASHBOARD))
          }
        >
          <Arrow className="rotate-180" />{" "}
          <span className="ml-16">
            {translate("buttons.backToDashboard", {
              ns: "app",
              defaultValue: "Zurück zum dashboard",
            })}
          </span>
        </Button>
      </div>
      <h1 className="mb-16">{t("page.title", "Dokumente")}</h1>
      <p className="text-medium mb-48">
        {t(
          "page.description",
          "Hier finden Sie alle Dokumente zu Ihrer Versicherung."
        )}
      </p>
      <div className="dokumente-page-content two-column-layout">
        <div className="left">
          <Card className="mb-48" type={Type.Standard}>
            <Top
              title={data.unreadDocumentsAmount}
              icon={<DocumentIcon />}
              text={t(
                "inboxBox.unreadDocuments.label",
                "Hier finden Sie alle Dokumente zu Ihrer Versicherung."
              )}
            />
          </Card>

          <div className="list-view">
            {!!list.length && (
              <div className="header">
                <div className="column">
                  {t("listView.label.date", "Datum")}
                </div>
                <div className="column">
                  {t("listView.label.documentType", "Dokumenttyp")}
                </div>
                <div className="column">
                  {t("listView.label.documentState", "Status")}
                </div>
                <div className="column icon" />
              </div>
            )}

            {list &&
              list.map((doc, key) => (
                <div className="row-wrapper" key={key}>
                  <ExpandableRow
                    key={key}
                    content={
                      <div
                        className={`content ${doc.unread ? "unread" : "read"}`}
                      >
                        <div className="column">{parseDate(doc.date)}</div>
                        {/* Document type for desktop */}
                        <div className="column dokumenttyp">
                          {doc.documenttype}
                        </div>

                        <div className="column">
                          {doc.stateLabel && (
                            <Pill
                              text={doc.stateLabel}
                              type={PillTypes.Warning}
                            />
                          )}
                        </div>

                        <div className="column icon">
                          {/*<a href={doc.pdfLink} download target="_blank">*/}
                          <Button
                            type={ButtonTypes.Text}
                            color={ButtonColors.Action}
                            isDisabled={props.isDrawerLoading}
                            onClick={() => onDocumentOpen(doc.pdfLink)}
                          >
                            <DownloadIcon />
                          </Button>
                          {/*</a>*/}
                        </div>

                        <div className="column dokumenttyp-mobile">
                          {doc.documenttype}
                        </div>
                      </div>
                    }
                    more={<div>{t("listView.viewMore", "Mehr Sehen")}</div>}
                  />
                </div>
              ))}
          </div>
        </div>

        <div className="right">
          <Card>
            {sidebarBoxes && (
              <HygraphSidebarComponents
                boxes={sidebarBoxes}
                data={data}
                callback={handleSidebarCallback}
              />
            )}
          </Card>
        </div>
      </div>

      <Document
        closeModal={closeModal}
        isModalOpen={isModalOpen}
        onDocumentLoad={onDocumentLoad}
        pdfLink={pdfLink}
      ></Document>
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  documentList: state.document.documentList,
  documentsSummary: state.document.documentsSummary,
  documentData: state.document.documentData,
  openDocument: state.document.openDocument,
  isDrawerLoading: state.app.isDrawerLoading,
  loading: state.app.loading,
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  getDocumentList: bindActionCreators(getDocumentList, dispatch),
  getDocumentsSummary: bindActionCreators(getDocumentsSummary, dispatch),
  getPdf: bindActionCreators(getPdf, dispatch),
  insuranceFormSet: bindActionCreators(insuranceFormSet, dispatch),
  generateInsuranceFormSet: bindActionCreators(
    generateInsuranceFormSet,
    dispatch
  ),
  setLoading: (val) => dispatch(setLoading(val)),
  setDrawerLoading: (val) => dispatch(setDrawerLoading(val)),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

export default withRouter(connector(Dokumente));
